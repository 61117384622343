<template>

  <div>

    <div class="card card-content"">
      <div class="card-header">
        <el-card-title :title="$t('modules.hosting.users')" />
      </div>
      <div class="card-body">
        <div v-if="hosting">
          <module-entity-permissions
            :saving="loading"
            :entity="hostingClone"
            :tribes="tribes"
            :users="users"
          />
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import _ from 'lodash'

import HostingTab from './_Tab'

export default {
  extends: HostingTab,
  name: 'Users',
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
    users () {
      return this.$store.getters['world/getUsers']
    },
    tribes () {
      return []
    },
  },
  methods: {
  },
}
</script>
